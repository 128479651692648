import React from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Heading3, Heading4, Heading5, TextBodyLarge } from '../TextStyles';
import styled from 'styled-components';
import { mq } from 'styles/vars/media-queries.style';

const RichText = ({ content, delay = 0 }) => {
  const options = {
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <Heading3>{children}</Heading3>,
      [BLOCKS.HEADING_2]: (node, children) => <Heading4>{children}</Heading4>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading5>{children}</Heading5>,
      [BLOCKS.HEADING_5]: (node, children) => (
        <Heading5 as="h5">{children}</Heading5>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <TextBodyLarge as="p">{children}</TextBodyLarge>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <Quote>
          <TextBodyLarge>{children}</TextBodyLarge>
        </Quote>
      ),
      [INLINES.HYPERLINK]: (node) => {
        return (
          <a href={node.data.uri} target={`_blank`}>
            {node.content[0].value}
          </a>
        );
      },
    },
  };

  return renderRichText(content, options);
};

export default RichText;

const Quote = styled.p`
  ${mq.tiny} {
    padding-left: 50px;
  }
  ${mq.desk} {
    padding-left: 75px;
  }
`;
